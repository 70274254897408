import './App.css';
import 'bootswatch/dist/materia/bootstrap.min.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop'

function App() {
  return (
    <>
    <Header />
    <ScrollTop />
    <Footer />
    </>
  );
}

export default App;
